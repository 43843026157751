import { StyleFunction, Styles } from "@helpers/hooks/useStyles"
import { TextStyle, ViewStyle } from "react-native"

export interface VoteBottomNavigatorStyles extends Styles {
    errorIcon: TextStyle
    button: ViewStyle
    submitButton: ViewStyle
}

export const bottomNavigatorStyles: StyleFunction<
    VoteBottomNavigatorStyles
> = ({ theme }) => {
    return {
        errorIcon: {
            ...theme.font.navbarErrorIcon,
        },
        button: {
            marginRight: 0,
            marginLeft: 0,
            paddingLeft: 5,
            paddingRight: 5,
        },
        submitButton: {
            width: 120,
            marginRight: 0,
            marginLeft: 0,
        },
    }
}
