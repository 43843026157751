import { AxiosRequestConfig, AxiosRequestHeaders, AxiosResponse } from "axios"

export enum StorageKeys {
    IS_AUTHENTICATED = "isAuthenticated",
    COOKIE = "cookie-based-fingerprint",
    POLL_SETTINGS = "poll-settings",
    USER_NAME = "userName",
    POLL_TITLE = "pollTitle",
    POLL_OPTIONS = "pollOptions",
    HAS_CONSENTED = "has-consented",
    DEVICE_ID = "deviceId",
    USER_ID = "userId",
    ANALYTICS_DEVICE_ID = "analyticsDeviceId",
    REMEMBER_SUBMIT_SHOWN = "rememberSubmitShown",
}

export enum CookieKeys {
    IS_AUTHENTICATED = "polls-isAuthenticated",
    USER_NAME = "polls-userName",
}

export type HttpMethod =
    | "get"
    | "post"
    | "delete"
    | "put"
    | "patch"
    | "head"
    | "options"

export type ApiResponse<T> = AxiosResponse<T>
export type ApiRequestHeaders = AxiosRequestHeaders
export type ApiRequestConfig = AxiosRequestConfig
export type ApiRequestData = Record<string, unknown> | Record<string, unknown>[]

export interface FingerprintUser {
    id: string
    cognitoId?: string | undefined
    name?: string | undefined
    signInMethod?: string | undefined
    phoneNumber?: string | undefined
    userDefaults: UserDefaults
    createdAt: Date
    lastSeenAt: Date
}

export type AuthenticatedUserInfo = {
    cognitoId: string
    signInMethod: string
}

export interface CognitoUser {
    username: string
    pool: Pool
    Session: string
    client: Client
    signInUserSession?: any
    authenticationFlowType: string
    storage: Storage
    keyPrefix: string
    userDataKey: string
    challengeName: string
    challengeParam: ChallengeParam
}
export interface PollData {
    id: string
    title: string
    ownerId: string
    url: string
    state?: "live" | "draft"
    shareLink: string
    shareLinkTemplate?: string
    shareLinkTemplateWebp?: string,
    shareLinkTemplateJpeg?: string,
    options: PollOption[] | []
    settings: PollSettings
    totalVoteCount: number
    previewImageFilename: string
    previewImageFileUuid: string
    version: string
    minCompatibleVersion: string
    timeZoneId: string
}

export interface UserDefaults {
    pollSettings: PollSettings
}

export interface PollSettings {
    editPoll: boolean
    seeWhoVoted: boolean
    multipleVotes: boolean
    postVoteAction?: "none" | "shareVote" | "downloadPollsApp"
    sendType?: string
    declareWinner?: boolean
}

export type PollMutableSettings = "seeWhoVoted" | "editPoll" | "multipleVotes"

export type PollOptionValueType = "true"
export type PollOptionType = "text" | "link" | "dateTime" | "defaultTemplate"

type PollOptionBase = {
    id: string
    title: string
    ownerId: string
    recentVotes: PollRecentVotes[] | []
    votesByCurrentUser: {
        optionId: string
        value: PollOptionValueType
    }[]
    voteCount: {
        count: number
        value: PollOptionValueType
    }[]
    type: PollOptionType
}

export interface PollOptionText extends PollOptionBase {
    type: "text"
}
export interface PollOptionLink extends PollOptionBase {
    type: "link"
    subtitle?: string
    url?: string
    faviconUrl?: string
    imageUrl?: string
    siteName?: string
}
export interface PollOptionDateTime extends PollOptionBase {
    type: "dateTime"
    subtitle?: string
    startDate?: string
    endDate?: string
}
export interface PollOptionDefault extends PollOptionBase {
    type: "defaultTemplate"
    subtitle?: string
    details?: string
    imageUrl?: string
    resourceId?: string
    url: string
}

export type PollOption =
    | PollOptionText
    | PollOptionLink
    | PollOptionDateTime
    | PollOptionDefault

export interface ListItem {
    id: string
    index: number
    type: PollOptionType
    data: PollOption
}

export interface PollRecentVotes {
    id: string
    optionId: string
    createdAt: string
    voter: {
        id: string
        name: string
    }
}

export interface PollHistoryItem {
    id: string
    ownerId: string
    title: string
    createdAt: string
    updatedAt: string
}

export interface Pool {
    userPoolId: string
    clientId: string
    client: Client
    advancedSecurityDataCollectionFlag: boolean
    storage: Storage
}

export interface Client {
    endpoint: string
    fetchOptions: FetchOptions
}

export interface ChallengeParam {
    username: string
    phone: string
}

export interface StorageContents {
    user: string
    cookieBasedFingerprint: string
    EXPO_CONSTANTS_INSTALLATION_ID: string
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface FetchOptions {}

export enum SignInFlow {
    onboarding,
    launchPoll,
    profile,
    editPoll,
}

export enum AddOptionFlow {
    draftPoll,
    voting,
    editPoll,
}
