import { StyleFunction, Styles } from "@helpers/hooks/useStyles"
import { ImageStyle, PixelRatio, TextStyle, ViewStyle } from "react-native"

export interface DefaultCardTemplateStyles extends Styles {
    container: ViewStyle
    imageBackground: ViewStyle
    contentContainer: ViewStyle
    checkboxContainer: ViewStyle
    voteCountText: TextStyle
    image: ImageStyle
    textContainer: ViewStyle
    title: TextStyle
    subtitle: TextStyle
}

export const DefaultCardTemplateStyles: StyleFunction<
    DefaultCardTemplateStyles
> = ({ theme }) => {
    const ratio = PixelRatio.get()

    return {
        container: {
            height: 200 / ratio,
        },
        imageBackground: {
            width: 932 / ratio,
            height: 200 / ratio,
        },
        contentContainer: {
            flex: 1,
            flexDirection: "row",
            margin: 20 / ratio,
            marginTop: 2 / ratio,
            alignItems: "center",
        },
        checkboxContainer: {
            width: 130 / ratio,
            paddingHorizontal: 10 / ratio,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        voteCountText: {
            ...theme.font.previewImageVotesCountText,
            fontSize: theme.font.previewImageVotesCountText.fontSize / ratio,
            lineHeight: theme.font.previewImageVotesCountText.lineHeight / ratio,
            marginTop: 10 / ratio,
        },
        image: {
            height: 130 / ratio,
            width: 215 / ratio,
            borderRadius: 10,
        },
        textContainer: {
            paddingVertical: 25 / ratio,
            paddingLeft: 20 / ratio,
            paddingRight: 30 / ratio,
            width: 700 / ratio,
            flex: 1,
            height: "100%",
        },
        title: {
            ...theme.font.previewImageOptionTitle,
            fontSize: theme.font.previewImageOptionTitle.fontSize / ratio,
            lineHeight: theme.font.previewImageOptionTitle.lineHeight / ratio,
            flexWrap: "wrap",
            marginBottom: "auto",
        },
        subtitle: {
            ...theme.font.previewImageOptionSubtitle,
            fontSize: theme.font.previewImageOptionSubtitle.fontSize / ratio,
            lineHeight: theme.font.previewImageOptionSubtitle.lineHeight / ratio,
            flexWrap: "wrap",
            marginBottom: 5 / ratio,
        },
    }
}
