import { useEffect, useState } from "react"
import { Dimensions, Platform, ScaledSize } from "react-native"
import { isSafari } from "react-device-detect"

const getPlatformSpecificScreen = () => {
    if (Platform.OS === "web") return "window"
    else if (Platform.OS === "android") return "window"
    else if (isSafari) return "window"
    else return "screen"
}

const getPlatformSpecificScreenSize = (
    screen: ScaledSize,
    window: ScaledSize,
) => {
    if (Platform.OS === "web") return window
    else if (Platform.OS === "android") return window
    else if (isSafari) return window
    else return screen
}

interface ScreenSizes {
    screen: ScaledSize
    window: ScaledSize
}

export const useScreenDimensions = () => {
    const initialScreenData = Dimensions.get(getPlatformSpecificScreen())
    const [screenData, setScreenData] = useState(initialScreenData)

    useEffect(() => {
        const onChange = ({ screen, window }: ScreenSizes) => {
            const size = getPlatformSpecificScreenSize(screen, window)
            setScreenData(size)
        }

        const subscription = Dimensions.addEventListener("change", onChange)

        return () => subscription.remove()
    }, [])

    return {
        ...screenData,
        isLandscape: screenData.width > screenData.height,
        isMobileView: Platform.OS === "web" && screenData.width < 600,
    }
}
