import { useScreenDimensions } from "@helpers/hooks/useScreenDimensions"
import { useCallback } from "react"
import { Linking } from "react-native"

export const useRedirect = () => {
    const { isMobileView } = useScreenDimensions()

    // On mobile web specific we want urls to open in the same tab
    return useCallback(
        (url: string) => {
            isMobileView ? (window.location.href = url) : Linking.openURL(url)
        },
        [isMobileView],
    )
}
