import { LinkingOptions } from "@react-navigation/native"
import * as Linking from "expo-linking"
import { ScreenName } from "@screens"
import { isPlatformNative } from "@helpers/isPlatformNative"
import AirbridgeService from "@AirbridgeService/AirbridgeService"
import Logger from "@services/logger"

const isNative: boolean = isPlatformNative()

export type ScreenParamList = {
    [ScreenName.THEME_SCREEN]: undefined
    [ScreenName.PROFILE_SCREEN]: undefined
    [ScreenName.PROFILE_SCREEN_SIGN_IN]: undefined
    [ScreenName.VOTE_SCREEN]: {
        pollId: string
        theme?: string
        share?: boolean
        embeddedState?: string
        pending?: boolean
    }
    [ScreenName.CREATE_POLL_SCREEN]: undefined
    [ScreenName.CONSENT_SCREEN]: undefined
    [ScreenName.HOME_SCREEN]: undefined
    [ScreenName.SIGN_IN_SCREEN]: undefined
    [ScreenName.SIGN_IN_SCREEN_CONFIRM]: undefined
    [ScreenName.GET_APP_SCREEN]: undefined
    [ScreenName.SELECT_POLL_SCREEN]: {
        pollId: string
        theme?: string
        share?: boolean
        embeddedState?: string
        pending?: boolean
    }
}

const configNative = {
    screens: {
        HomeStack: {
            initialRouteName: ScreenName.HOME_SCREEN,
            screens: {
                [ScreenName.HOME_SCREEN]: "home",
                [ScreenName.VOTE_SCREEN]: "polls/:pollId",
                [ScreenName.CREATE_POLL_SCREEN]: "createPoll",
                [ScreenName.PROFILE_SCREEN]: "profile",
            },
        },
        [ScreenName.THEME_SCREEN]: "theme",
        [ScreenName.CONSENT_SCREEN]: "consent",
        [ScreenName.SIGN_IN_SCREEN]: "signin",
        [ScreenName.PROFILE_SCREEN_SIGN_IN]: "profile/signIn",
        [ScreenName.SIGN_IN_SCREEN_CONFIRM]: "signinconfirm",
    },
}

const configWeb = {
    screens: {
        [ScreenName.VOTE_SCREEN]: "polls/:pollId",
        [ScreenName.CREATE_POLL_SCREEN]: "createPoll",
        [ScreenName.PROFILE_SCREEN]: "profile",
        [ScreenName.THEME_SCREEN]: "theme",
        [ScreenName.CONSENT_SCREEN]: "consent",
        [ScreenName.SIGN_IN_SCREEN]: "signin",
        [ScreenName.PROFILE_SCREEN_SIGN_IN]: "profile/signIn",
        [ScreenName.SIGN_IN_SCREEN_CONFIRM]: "signinconfirm",
        [ScreenName.GET_APP_SCREEN]: "getTheApp",
        [ScreenName.SELECT_POLL_SCREEN]: "poll-drafts/:pollId",
    },
}

export const linking: LinkingOptions<ScreenParamList> = {
    prefixes: [
        "polls://",
        "https://*.pollsplatform.com",
        Linking.createURL("/"),
    ],
    async getInitialURL() {
        Logger.info("AIRBRIDGE: initial url")

        const initialUrl = await Linking.getInitialURL()
        Logger.info(`initial url: ${initialUrl}`)

        const latestDeeplink = AirbridgeService?.getLatestDeepLink()
        Logger.info(`initial deep link: ${latestDeeplink}`)

        return latestDeeplink ?? initialUrl
    },

    subscribe(listener) {
        Logger.info("AIRBRIDGE: subscribe")

        const shouldUseAirbridgeDeepLinking = isNative && AirbridgeService

        if (shouldUseAirbridgeDeepLinking) {
            AirbridgeService?.addDeeplinkListener(url => {
                if (url) {
                    Logger.info(`received deep link: ${url}`)
                    listener(url)
                }
            })
        } else {
            const onReceiveURL = ({ url }: { url: string }) => listener(url)
            const eventListener = Linking.addEventListener("url", onReceiveURL)

            return () => {
                eventListener.remove()
            }
        }
    },
    config: isNative ? configNative : configWeb,
}
