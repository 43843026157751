import { ImageBackground, PixelRatio, Text, View } from "react-native"
import useStyles from "@helpers/hooks/useStyles"
import React, { useMemo } from "react"
import { PollsCardWrapperStyles } from "./PollsCardWrapper.styles"
import { Checkbox, PreviewImageProgressBar } from "@components"
import noop from "lodash/noop"
import formatNumber from "@helpers/compactNumberFormatter"
import { commonStyles } from "@styles/Main.styles"
import { PollOption, PollOptionLink, PollOptionText } from "@types"

interface PollCardWrapperProps {
    seeWhoVoted: boolean
    option: PollOptionText | PollOptionLink
    totalCounter: number
    isChecked: boolean
    children: React.ReactNode
    onLoaded?: (id: PollOption["id"]) => void
}

const PollsCardWrapper = ({
    seeWhoVoted,
    option,
    totalCounter,
    isChecked,
    children,
    onLoaded,
}: PollCardWrapperProps) => {
    const { styles, theme } = useStyles(PollsCardWrapperStyles)
    const { styles: defaultStyles } = useStyles(commonStyles)

    const ratio = PixelRatio.get()

    const voteCount = useMemo(
        () => option?.voteCount[0]?.count && option.voteCount[0].count,
        [option.voteCount],
    )

    const handleLoaded = () => onLoaded?.(option.id)

    return (
        <View style={styles.container}>
            <ImageBackground
                source={theme.image.previewImageCard}
                resizeMode="stretch"
                onLoad={handleLoaded}
                style={styles.imageBackground}
            >
                <View style={styles.contentContainer}>
                    <View style={styles.checkboxContainer}>
                        <Checkbox
                            size={70 / ratio}
                            checked={seeWhoVoted ? isChecked : false}
                            onPress={noop}
                        />
                        <Text style={styles.voteCountText}>
                            {voteCount === 1
                                ? `${voteCount} Vote`
                                : `${formatNumber(voteCount)} Votes`}
                        </Text>
                    </View>

                    <View style={styles.textContainer}>
                        <View style={defaultStyles.rowFlex}>{children}</View>
                        <PreviewImageProgressBar
                            votes={voteCount ?? 0}
                            totalVotes={totalCounter}
                        />
                    </View>
                </View>
            </ImageBackground>
        </View>
    )
}

export default PollsCardWrapper
