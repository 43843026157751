import { UserContext } from "@contexts/UserDataProvider"
import React, {
    ReactNode,
    useCallback,
    useContext,
    useEffect,
    useReducer,
} from "react"
import { PollContext } from "./"
import { initialState, PollActionType, pollReducer } from "./PollReducer"
import { useGetPollFlow } from "@flows/useGetPollFlow"
import { useUpdatePollFlow } from "@flows/useUpdatePollFlow"
import { AuthenticationState } from "@contexts/UserDataProvider/UserReducer"
import { SharePollModal, ShareVoteModal } from "@components"
import {
    DrawerActionType,
    DrawerContext,
    ScreenShotActionType,
    ScreenShotContext,
} from "@contexts"
import { storageService } from "@services/storage"
import { useLaunchPollFlow } from "@flows/useLaunchPollFlow"
import { useGetDraftPollFlow } from "@flows/useGetDraftPollFlow"
import { StorageKeys } from "@types"
import { useGetPollHistoryFlow } from "@flows/useGetPollHistoryFlow"
import { getVoteArrayDifference } from "@helpers/pollList"
import { t } from "i18next"
import { CreateOwnPolls, ModalName } from "@components/Drawer/content"
import { usePublishPollFlow } from "@flows/usePublishPollFlow"
import { ThemeContext } from "@contexts/ThemeContext"

interface PollContextProviderProps {
    children: ReactNode
}

export const PollContextProvider = ({ children }: PollContextProviderProps) => {
    const [pollState, pollDispatch] = useReducer(pollReducer, initialState)

    const {
        userState: { authStatus, deviceId },
    } = useContext(UserContext)

    const {
        pollId,
        updatePollData,
        isDataRestored,
        storeData,
        pollTitle,
        fromParams,
        pollTitleEdit,
        isLaunched,
        isCreateDraftMode,
        refreshPollData,
        refreshPollHistory,
        screenshotOnRefresh,
        votesToCompare,
        currentSessionVotesByUser,
        sequentialUpdates,
        data: { options, settings },
        openSharePoll: openSharePollFlag,
        optionsToPublish,
        pollPending,
    } = pollState

    const { drawerDispatch } = useContext(DrawerContext)
    const { screenShotDispatch } = useContext(ScreenShotContext)
    const { theme } = useContext(ThemeContext)

    const getPoll = useGetPollFlow(pollPending, pollDispatch)
    const getDraftPoll = useGetDraftPollFlow(pollDispatch)
    const updatePoll = useUpdatePollFlow({
        pollDispatch,
        drawerContent: {
            shareVote: <ShareVoteModal />,
            createOwnPoll: <CreateOwnPolls />,
        },
    })
    const launchPoll = useLaunchPollFlow(pollDispatch)
    const getPollHistory = useGetPollHistoryFlow(pollDispatch)
    const publishPoll = usePublishPollFlow(pollDispatch)

    const openSharePoll = useCallback(() => {
        drawerDispatch({
            type: DrawerActionType.SHOW_DRAWER,
            payload: {
                content: <SharePollModal />,
                dismissible: true,
                hasCloseButton: true,
                hasBackground: true,
                onDismiss: () => {
                    screenShotDispatch({
                        type: ScreenShotActionType.SET_SHARE_LINK,
                        payload: {
                            shareLink: "",
                        },
                    })
                },
                identifier: ModalName.SHARE_POLL,
            },
        })

        pollDispatch({
            type: PollActionType.LAUNCH_DONE,
        })
    }, [drawerDispatch, screenShotDispatch])

    const filterUpdates = useCallback(() => {
        const sequentialUpdates = [...pollState.sequentialUpdates]
        const currentVotes = [...pollState.currentSessionVotesByUser]

        const updates = sequentialUpdates.filter(entry => {
            if (entry.type === "removeOption") return entry

            const optionExist = options.findIndex(o => o.id === entry.option.id)

            if (optionExist >= 0) {
                return entry
            }
        })

        const currentVotesByUser = currentVotes.filter(vote => {
            const optionExist = options.findIndex(e => e.id === vote.optionId)

            if (optionExist >= 0) {
                return vote
            }
        })

        return {
            sequentialUpdates: updates,
            resultingVotes: currentVotesByUser,
        }
    }, [
        options,
        pollState.currentSessionVotesByUser,
        pollState.sequentialUpdates,
    ])

    useEffect(() => {
        if (updatePollData) {
            const { sequentialUpdates, resultingVotes } = filterUpdates()

            updatePoll({
                pollId: pollState.pollId,
                sequentialUpdates,
                resultingVotes,
            })
        }
    }, [updatePollData, updatePoll])

    useEffect(() => {
        if (storeData && isCreateDraftMode) {
            storageService.setItem(StorageKeys.POLL_OPTIONS, options)

            pollDispatch({ type: PollActionType.DATA_STORED })
        }
    }, [storeData, options, isCreateDraftMode])

    useEffect(() => {
        if (pollTitleEdit && isCreateDraftMode) {
            storageService.setItem(StorageKeys.POLL_TITLE, pollTitle)
        }
    }, [pollTitleEdit, pollTitle, isCreateDraftMode])

    useEffect(() => {
        if (fromParams && isCreateDraftMode) {
            storageService.setItem(StorageKeys.POLL_TITLE, pollTitle)
        }
    }, [fromParams, pollTitle, isCreateDraftMode])

    useEffect(() => {
        if (
            refreshPollData &&
            pollId &&
            !isCreateDraftMode &&
            deviceId &&
            (authStatus === AuthenticationState.AUTHENTICATED ||
                authStatus === AuthenticationState.UNAUTHENTICATED)
        ) {
            getPoll(pollId, screenshotOnRefresh)
        }
    }, [
        authStatus,
        getPoll,
        refreshPollData,
        pollId,
        isCreateDraftMode,
        screenshotOnRefresh,
        deviceId,
    ])

    useEffect(() => {
        if (
            refreshPollHistory &&
            authStatus === AuthenticationState.AUTHENTICATED
        ) {
            getPollHistory()
        }
    }, [authStatus, getPollHistory, refreshPollHistory])

    useEffect(() => {
        if (pollState.launchPoll) {
            launchPoll({
                title: pollTitle,
                optionsList: options,
                pollSettings: settings,
            })
        }
    }, [pollState.launchPoll])

    useEffect(() => {
        const resultsLeft = getVoteArrayDifference(
            votesToCompare,
            currentSessionVotesByUser,
        )
        const resultsRight = getVoteArrayDifference(
            currentSessionVotesByUser,
            votesToCompare,
        )

        const resultsUpdated =
            [...resultsLeft, ...resultsRight].length > 0 ||
            sequentialUpdates.length > 0

        const needUpdating = options.length > 1 ? resultsUpdated : false

        if (pollState.needUpdating === needUpdating) return

        pollDispatch({
            type: PollActionType.NEED_UPDATING,
            payload: { needUpdating },
        })
    }, [
        votesToCompare,
        currentSessionVotesByUser,
        sequentialUpdates,
    ])

    useEffect(() => {
        if (isLaunched && pollId) {
            drawerDispatch({
                type: DrawerActionType.DISMISS_DRAWER,
            })

            drawerDispatch({
                type: DrawerActionType.SHOW_SUCCESS_MODAL,
                payload: {
                    title: t("createPollScreen.pollCreated"),
                    message: t("createPollScreen.shareWithFriends"),
                    animation: theme.lottie.createPollSuccess,
                    duration: 1500,
                    animationEnd: 0.95,
                    onDismiss: openSharePoll,
                },
            })
        }
    }, [drawerDispatch, openSharePoll, pollId, isLaunched])

    useEffect(() => {
        if (isCreateDraftMode && !isDataRestored) getDraftPoll()
    }, [getDraftPoll, isCreateDraftMode, isDataRestored])

    useEffect(() => {
        if (optionsToPublish.length) {
            publishPoll({ poll: pollState.data, optionsToPublish })
        }
    }, [optionsToPublish])

    useEffect(() => {
        if (openSharePollFlag && !refreshPollData) {
            // Timeout is in AC so don't remove it
            setTimeout(() => {
                openSharePoll()
            }, 3500)
        }
    }, [refreshPollData, openSharePollFlag])

    return (
        <PollContext.Provider value={{ pollState, pollDispatch }}>
            {children}
        </PollContext.Provider>
    )
}
