import { BackButton, Title } from "@components"
import {
    AnalyticsActionType,
    AnalyticsContext,
    NavigationContext,
    UserContext,
} from "@contexts"
import { AuthenticationState } from "@contexts/UserDataProvider/UserReducer"
import { useScreenDimensions } from "@helpers/hooks/useScreenDimensions"
import useStyles from "@helpers/hooks/useStyles"
import { isPlatformNative } from "@helpers/isPlatformNative"
import { ScreenName } from "@screens"
import React, { useCallback, useContext } from "react"
import { Linking, Pressable, View } from "react-native"
import { navHeaderStyles } from "./NavHeader.styles"
import { getAppConfig } from "@services/appConfig"
import { HeaderLogo } from "@components/NavHeader/HeaderLogo"
import { AnalyticsEvent } from "@services/analytics"

const NavHeader = () => {
    const {
        userState: { authStatus },
    } = useContext(UserContext)
    const { analyticsDispatch } = useContext(AnalyticsContext)

    const isAuthenticated = authStatus === AuthenticationState.AUTHENTICATED
    const { isMobileView } = useScreenDimensions()
    const { styles } = useStyles(navHeaderStyles)
    const isMobile = isPlatformNative() || isMobileView
    const { navigate, goBack, canGoBack } = useContext(NavigationContext)
    const { tenantConfig } = getAppConfig()

    const handleOnPress = useCallback(async (): Promise<void> => {
        const nativeNavTarget = isAuthenticated
            ? ScreenName.HOME_SCREEN
            : ScreenName.SIGN_IN_SCREEN

        if (tenantConfig.type === "customer")
            analyticsDispatch({
                type: AnalyticsActionType.LOG_ANALYTIC,
                payload: {
                    analyticId: AnalyticsEvent.tapVotePartnerLogo,
                },
            })

        isPlatformNative()
            ? navigate({ screen: nativeNavTarget })
            : Linking.openURL(tenantConfig.homeUrl)
    }, [isAuthenticated, isMobile])

    const handleBack = useCallback(() => {
        goBack()
    }, [goBack])

    return (
        <View style={styles.container}>
            {canGoBack && (
                <View style={styles.backButtonContainer}>
                    <BackButton
                        align="left"
                        onPress={handleBack}
                        styles={styles.backButton}
                    />
                </View>
            )}
            <Pressable onPress={handleOnPress} style={styles.content}>
                <HeaderLogo />
                <View>
                    {tenantConfig.name && (
                        <Title style={styles.title} title={tenantConfig.name} />
                    )}
                    {!isMobile && tenantConfig.subtitle && (
                        <Title
                            style={styles.subtitle}
                            title={tenantConfig.subtitle}
                        />
                    )}
                </View>
            </Pressable>
        </View>
    )
}

export default NavHeader
