import { Image, Text } from "react-native"
import useStyles from "@helpers/hooks/useStyles"
import React, { useCallback, useEffect, useState } from "react"
import { LinkCardTemplateStyles } from "./LinkCardTemplate.styles"
import { PollOption, PollOptionLink } from "@types"
import { proxy } from "@services/poll/proxy"
import { PollsCardWrapper } from "@components/PreviewImage/templates/PollsCardWrapper"

interface LinkCardTemplateItemProps {
    seeWhoVoted: boolean
    option: PollOptionLink
    totalCounter: number
    onLoaded: (id: PollOption["id"]) => void
    isChecked: boolean
}

const LinkCardTemplate = ({
    seeWhoVoted,
    option,
    totalCounter,
    onLoaded,
    isChecked,
}: LinkCardTemplateItemProps) => {
    const { styles } = useStyles(LinkCardTemplateStyles)
    const [imageEnc, setImageEnc] = useState("")

    const handleLoaded = () => onLoaded(option.id)

    const toDataUrl = useCallback(async () => {
        if (!option.imageUrl) {
            handleLoaded()
            return
        }

        const { isSuccess, data } = await proxy({ url: option.imageUrl })

        if (isSuccess && data) {
            const reader = new FileReader()

            reader.onloadend = () => {
                if (typeof reader.result === "string")
                    setImageEnc(reader.result)
            }
            reader.readAsDataURL(data)
        }
    }, [])

    useEffect(() => {
        toDataUrl()
    }, [])

    return (
        <PollsCardWrapper
            seeWhoVoted={seeWhoVoted}
            option={option}
            isChecked={isChecked}
            totalCounter={totalCounter}
        >
            {!!imageEnc.length && (
                <Image
                    source={{ uri: imageEnc }}
                    style={styles.image}
                    onLoad={handleLoaded}
                />
            )}
            <Text style={styles.title} ellipsizeMode={"tail"} numberOfLines={2}>
                {option.title}
            </Text>
        </PollsCardWrapper>
    )
}

export default LinkCardTemplate
