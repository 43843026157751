import React, { useCallback, useContext, useEffect } from "react"
import { Pressable, View } from "react-native"
import { drawerStyles } from "./Drawer.styles"
import { DrawerActionType, DrawerContext } from "@contexts"
import useStyles from "@helpers/hooks/useStyles"
import { Button } from "@components"
import { AnimatedView } from "../AnimatedView"
import { useScreenDimensions } from "@helpers/hooks/useScreenDimensions"
import { vibrate } from "@services/vibration"
import { AnimatedType } from "../AnimatedView/AnimatedView"
import { isPlatformNative } from "@helpers/isPlatformNative"

const Modal = () => {
    const { styles, theme } = useStyles(drawerStyles)
    const { height, isMobileView } = useScreenDimensions()
    const isNative = isPlatformNative()
    const {
        drawerState: {
            visible,
            dismissed,
            content,
            dismissible,
            hasCloseButton,
            hasBackground,
            isActive,
            isModal,
        },
        drawerDispatch,
    } = useContext(DrawerContext)

    const handleDismiss = useCallback(() => {
        if (!dismissed && visible) {
            drawerDispatch({ type: DrawerActionType.DISMISS_DRAWER })
        }
    }, [isActive, dismissed, visible])

    const handleBackgroundDismiss = useCallback(() => {
        if (dismissible) handleDismiss()
    }, [dismissible, handleDismiss])

    const handleAnimationComplete = useCallback(() => {
        if (dismissed) {
            drawerDispatch({ type: DrawerActionType.HIDE_DRAWER })
        } else {
            if (!isActive) drawerDispatch({ type: DrawerActionType.SET_ACTIVE })
        }
    }, [dismissed, isActive])

    useEffect(() => {
        if (visible) {
            vibrate("drawerAppear")
        }

        if (dismissed) {
            vibrate("normalButtonPress")
        }
    }, [visible, dismissed])

    const initialAnim = useCallback(() => {
        if (dismissed) {
            if (isNative && !isModal) {
                return {
                    type: AnimatedType.SLIDE_V,
                    speed: 20,
                    from: 0,
                    to: height,
                }
            } else {
                return isMobileView && !isModal
                    ? {
                          type: AnimatedType.SLIDE_V,
                          speed: 20,
                          from: 0,
                          to: height,
                      }
                    : { type: AnimatedType.FADE, from: 1, to: 0 }
            }
        } else {
            if (isNative && !isModal) {
                return {
                    type: AnimatedType.SLIDE_V,
                    speed: 10,
                    from: height,
                    to: 0,
                }
            } else {
                return isMobileView && !isModal
                    ? {
                          type: AnimatedType.SLIDE_V,
                          speed: 10,
                          from: height,
                          to: 0,
                      }
                    : {
                          type: AnimatedType.ZOOM,
                          from: 0.7,
                          to: 1,
                          speed: 10,
                          bounciness: 10,
                      }
            }
        }
    }, [isMobileView, isNative, dismissed, isModal])

    const overlayAnim = useCallback(() => {
        if (dismissed) {
            return { type: AnimatedType.FADE, from: 0.7, to: 0, duration: 300 }
        } else {
            return { type: AnimatedType.FADE, from: 0, to: 0.7 }
        }
    }, [dismissed])

    return visible ? (
        <View style={styles.view} pointerEvents={"auto"}>
            <View
                style={[
                    isModal
                        ? styles.outerContainerModal
                        : styles.outerContainer,
                ]}
            >
                <Pressable
                    style={styles.view}
                    onPress={handleBackgroundDismiss}
                >
                    <AnimatedView
                        id="drawer_overlay"
                        style={styles.overlay}
                        animProps={overlayAnim()}
                    />
                </Pressable>
                <View
                    style={isModal ? styles.containerModal : styles.container}
                >
                    <AnimatedView
                        id="drawer_body"
                        style={styles.animated}
                        animProps={initialAnim()}
                        onAnimationCompleted={handleAnimationComplete}
                    >
                        <View
                            style={[
                                styles.content,
                                hasBackground && styles.background,
                                isModal &&
                                    hasBackground &&
                                    styles.backgroundModal,
                            ]}
                        >
                            {hasCloseButton && (
                                <View
                                    style={
                                        isModal
                                            ? styles.closeButtonContainerModal
                                            : styles.closeButtonContainer
                                    }
                                >
                                    <Button
                                        variant="text"
                                        align="left"
                                        iconStyle={styles.closeIcon}
                                        iconLeft={theme.image.closeX}
                                        style={styles.closeButton}
                                        onPress={handleDismiss}
                                    />
                                </View>
                            )}
                            {content}
                        </View>
                    </AnimatedView>
                </View>
            </View>
        </View>
    ) : null
}

export default Modal
