import { useContext, useEffect, useMemo, useState } from "react"
import { merge } from "lodash"
import { Platform, StyleSheet } from "react-native"
import { ThemeContext } from "@contexts/ThemeContext"
import { useScreenDimensions } from "@helpers/hooks/useScreenDimensions"
import { Theme } from "@assets/theme/Theme"
export interface DynamicStyle {
    width: number | 0
    height: number | 0
    theme: Theme
    props?: { [key: string]: any }
}

export interface Styles {
    NATIVE?: Record<string, unknown>
    WEB?: Record<string, unknown>
    MOBILE?: Record<string, unknown>
}

export type StyleFunction<T extends Styles> = (arg: DynamicStyle) => T

const useStyles = <T extends Styles>(style: StyleFunction<T>) => {
    const { theme } = useContext(ThemeContext)
    const { width, height, isMobileView } = useScreenDimensions()

    const mergedStyles = useMemo(() => {
        const styles = style({ width, height, theme })
        const { WEB, MOBILE, NATIVE, ...defaultStyle } = styles
        return merge(
            defaultStyle,
            Platform.OS === "web"
                ? isMobileView
                    ? styles["MOBILE"]
                    : styles["WEB"]
                : styles["NATIVE"],
        )
    }, [style, width, height, theme])

    return { styles: StyleSheet.create(mergedStyles) as T, theme }
}

export default useStyles
