import { Image, ImageBackground, PixelRatio, Text, View } from "react-native"
import useStyles from "@helpers/hooks/useStyles"
import React, { useCallback, useEffect, useState } from "react"
import { PollOption, PollOptionDefault } from "@types"
import { Checkbox } from "@components"
import noop from "lodash/noop"
import { DefaultCardTemplateStyles } from "./DefaultCardTemplate.styles"
import formatNumber from "@helpers/compactNumberFormatter"
import { proxy } from "@services/poll/proxy"

interface DefaultCardTemplateItemProps {
    seeWhoVoted: boolean
    option: PollOptionDefault
    onLoaded: (id: PollOption["id"]) => void
    isChecked: boolean
}

const DefaultCardTemplate = ({
    seeWhoVoted,
    option,
    onLoaded,
    isChecked,
}: DefaultCardTemplateItemProps) => {
    const { styles, theme } = useStyles(DefaultCardTemplateStyles)
    const ratio = PixelRatio.get()
    const [imageEnc, setImageEnc] = useState("")

    const votesCount = option.voteCount[0]?.count || 0

    const handleLoaded = () => onLoaded(option.id)

    const toDataUrl = useCallback(async () => {
        if (!option.imageUrl) return

        const { isSuccess, data } = await proxy({ url: option.imageUrl })

        if (isSuccess && data) {
            const reader = new FileReader()

            reader.onloadend = () => {
                if (typeof reader.result === "string")
                    setImageEnc(reader.result)
            }
            reader.readAsDataURL(data)
        }
    }, [])

    useEffect(() => {
        toDataUrl()
    }, [])

    return (
        <View style={styles.container}>
            <ImageBackground
                source={theme.image.previewImageCard}
                resizeMode="stretch"
                style={styles.imageBackground}
            >
                <View style={styles.contentContainer}>
                    <View style={styles.checkboxContainer}>
                        <Checkbox
                            size={70 / ratio}
                            checked={seeWhoVoted ? isChecked : false}
                            onPress={noop}
                        />
                        <Text style={styles.voteCountText}>
                            {votesCount === 1
                                ? `${votesCount} Vote`
                                : `${formatNumber(votesCount)} Votes`}
                        </Text>
                    </View>

                    {!!imageEnc.length && (
                        <Image
                            source={{ uri: imageEnc }}
                            style={styles.image}
                            onLoad={handleLoaded}
                            resizeMode={"cover"}
                        />
                    )}

                    <View style={styles.textContainer}>
                        <Text
                            style={styles.title}
                            ellipsizeMode={"tail"}
                            numberOfLines={2}
                        >
                            {option.title}
                        </Text>
                        <Text
                            style={styles.subtitle}
                            ellipsizeMode={"tail"}
                            numberOfLines={1}
                        >
                            {option.subtitle}
                        </Text>
                    </View>
                </View>
            </ImageBackground>
        </View>
    )
}

export default DefaultCardTemplate
