import { StyleFunction, Styles } from "@helpers/hooks/useStyles"
import { ViewStyle } from "react-native"

export interface ScreenLayoutStyles extends Styles {
    container: ViewStyle
    outerContainer: ViewStyle
    innerContainer: ViewStyle
    animationContainer: ViewStyle
    keyboardAvoidingContainer: ViewStyle
    backBtn: ViewStyle
}

export const webScreenCardStyles: StyleFunction<ScreenLayoutStyles> = ({
    theme,
    height,
}) => {
    return {
        container: {
            display: "flex",
            flex: 1,
            width: "100%",
            alignItems: "center",
            justifyContent: "space-between",
            alignSelf: "center",
            overflow: "hidden",
            borderRadius: 0,
            minHeight: 300,
        },
        outerContainer: {
            position: "absolute",
            flex: 1,
            borderRadius: 0,
            minHeight: 300,
            width: "100%",
            maxHeight: "100%",
            alignItems: "center",
            alignSelf: "center",
            overflow: "hidden",
            backgroundColor: theme.color.appBackgroundCardDesktop,
        },
        animationContainer: {
            flex: 1,
            height: "100%",
            width: "100%",
            alignItems: "center",
        },
        keyboardAvoidingContainer: {
            width: "100%",
            position: "absolute",
            top: 0,
            justifyContent: "flex-end",
        },
        innerContainer: {
            flex: 1,
            height: "100%",
            width: "100%",
        },
        backBtn: {
            position: "absolute",
            left: 15,
            top: 20,
        },
        NATIVE: {
            backBtn: {
                display: "none",
            },
            innerContainer: {
                paddingHorizontal: 15,
                minHeight: height - 100,
            },
        },
        MOBILE: {
            outerContainer: {
                height: "100%",
                minWidth: theme.layout.appCardMobileWidth,
                borderRadius: 0,
            },
            backBtn: {
                display: "none",
            },
        },
        WEB: {
            outerContainer: {
                height: "calc(100vh - 140px)",
                borderRadius: theme.layout.appCardDesktopRadius,
                width: theme.layout.appCardDesktopWidth,
                minWidth: theme.layout.appCardDesktopWidth,
                marginTop: 105,
                paddingTop: 20,
                ...theme.shadow.appCardDesktopExterior.web,
            },
        },
    }
}
