import { StyleFunction, Styles } from "@helpers/hooks/useStyles"
import { ImageStyle, TextStyle, ViewStyle } from "react-native"

export interface ConsentScreenStyles extends Styles {
    infoLine: TextStyle
    infoLineBlue: TextStyle
    consentImage: ImageStyle
    imageContainer: ViewStyle
    titleContainer: ViewStyle
    infoContainer: ViewStyle
    pageTitle: TextStyle
    pageSubtitle: TextStyle
    agreeText: TextStyle
    agreeButton: ViewStyle
    agreeButtonContainer: ViewStyle
    greeting: TextStyle
    center: ViewStyle
    horizontal: ViewStyle
}

export const consentScreenStyles: StyleFunction<ConsentScreenStyles> = ({
    theme,
    width,
}) => {
    return {
        center: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },
        horizontal: {
            display: "flex",
            flexDirection: "row",
        },
        pageTitle: {
            ...theme.font.consentPageTitle,
        },
        pageSubtitle: {
            ...theme.font.consentPageSubtitle,
        },
        greeting: {
            ...theme.font.consentGreeting,
        },
        infoLine: {
            ...theme.font.consentInfoLine,
        },
        infoLineBlue: {
            ...theme.font.consentInfoLine,
            color: "#007AFE",
        },
        titleContainer: {
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            width: "100%",
            height: 100,
            marginTop: 80,
        },
        infoContainer: {
            justifyContent: "flex-start",
            alignItems: "center",
            width: "100%",
            height: 100,
            marginBottom: 50,
        },
        imageContainer: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: 250,
            marginBottom: 50,
        },
        consentImage: {
            height: 330,
            width: 330,
            marginTop: 10,
            marginBottom: 10,
            backgroundColor: "transparent",
        },
        agreeText: {
            ...theme.font.consentPageAgreeButtonTitle,
        },
        agreeButtonContainer: {
            height: 50,
        },
        agreeButton: {
            width: width - 20,
        },
    }
}
