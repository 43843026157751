import { Reducer } from "react"
import Logger from "@services/logger"
import { AppStateStatus } from "react-native"

export enum AppActionType {
    APP_STATE = "appState",
    NAV_READY = "navigationReady",
    SET_CONTAINER_HEIGHT = "setContainerHeight",
    SET_EMBEDDED_STATE = "setEmbeddedState",
}

export enum EmbeddedState {
    SDK_SIDEBAR = "sdk-sidebar",
    SDK_MODAL = "sdk-modal",
    EXTENSION_SIDEBAR = "extension-sidebar",
}

export type Action =
    | { type: AppActionType.APP_STATE; payload: { appState: AppStateStatus } }
    | { type: AppActionType.NAV_READY; payload: { navReady: boolean } }
    | {
          type: AppActionType.SET_CONTAINER_HEIGHT
          payload: { containerHeight: number }
      }
    | {
          type: AppActionType.SET_EMBEDDED_STATE
          payload: { embeddedState: EmbeddedState }
      }

export type AppState = {
    appState: AppStateStatus
    navReady: boolean
    keypad: boolean
    containerHeight: number
    embeddedState?: EmbeddedState
}

export const initialState: AppState = {
    appState: "active",
    navReady: false,
    keypad: false,
    containerHeight: 400,
    embeddedState: undefined,
}

export const appReducer: Reducer<AppState, Action> = (state, action) => {
    Logger.info(`ACTION: ${action.type}`)
    switch (action.type) {
        case AppActionType.APP_STATE: {
            const { appState } = action.payload
            return {
                ...state,
                appState,
            }
        }
        case AppActionType.NAV_READY: {
            const { navReady } = action.payload
            return {
                ...state,
                navReady,
            }
        }
        case AppActionType.SET_CONTAINER_HEIGHT: {
            const { containerHeight } = action.payload
            return {
                ...state,
                containerHeight,
            }
        }
        case AppActionType.SET_EMBEDDED_STATE: {
            const { embeddedState } = action.payload
            return {
                ...state,
                embeddedState,
            }
        }
        default: {
            throw new Error(`Unhandled action type: ${action}`)
        }
    }
}
