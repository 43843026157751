import React, { useCallback, useEffect, useMemo, useState } from "react"
import { Image, ImageBackground, Text, View } from "react-native"
import { ScreenShotActionType } from "@contexts"
import { PreviewImageStyles } from "./PreviewImage.styles"
import useStyles from "@helpers/hooks/useStyles"
// @ts-ignore
import { captureRef } from "react-native-view-shot-with-web-support"
import Logger from "@services/logger"
import { PollOption } from "@types"
import { ScreenShotAction } from "@contexts/ScreenShotProvider/ScreenShotReducer"
import PreviewImageCardTemplate from "@components/PreviewImage/PreviewImageCardTemplate"
import { captureMessage } from "@services/sentry"
import { Vote } from "@contexts/PollContext/PollReducer"

interface PreviewImageProps {
    previewRef: any
    sortedList: PollOption[]
    totalCounter: number
    title: string
    seeWhoVoted: boolean
    screenShotDispatch: ScreenShotAction
    currentVotes: Vote[]
}

export const LAST_CARD_INDEX = 3

const PreviewImage: React.FC<PreviewImageProps> = ({
    previewRef,
    sortedList,
    totalCounter,
    title,
    seeWhoVoted,
    screenShotDispatch,
    currentVotes,
}) => {
    const { styles, theme } = useStyles(PreviewImageStyles)

    const [backgroundCompleted, setBackgroundCompleted] =
        useState<boolean>(false)
    const [cardsLoaded, setCardsLoaded] = useState<boolean>(false)
    const [numCards, setNumCards] = useState<PollOption["id"][] | []>([])

    useEffect(() => {
        if (
            cardsLoaded &&
            (theme.image.previewImageBackground ? backgroundCompleted : true)
        ) {
            setTimeout(() => {
                saveImage()
            }, 100) // EXTRA DELAY - SUPER NEEDED FOR MAKE SCREENSHOT CORRECTLY
        }
    }, [cardsLoaded, backgroundCompleted])

    const saveImage = useCallback(async () => {
        try {
            const result = await captureRef(previewRef, {
                result: "base64",
                format: "jpg",
                quality: 0.9,
            })
            //not empty preview image - more characters than just prefix
            if (result.length > 6) {
                screenShotDispatch({
                    type: ScreenShotActionType.SAVE_PREVIEW_IMAGE,
                    payload: {
                        previewImage: result,
                    },
                })
            }
        } catch (e) {
            captureMessage(JSON.stringify(e))
            Logger.info(JSON.stringify(e))
        }
    }, [])

    const onBackgroundCompleted = () => setBackgroundCompleted(true)

    const handleCardLoaded = useCallback(
        (id: PollOption["id"]) => {
            const loadedCards = [...numCards]
            const endLength =
                sortedList.length <= 3 ? sortedList.length : LAST_CARD_INDEX

            if (loadedCards.some(item => item === id)) return

            loadedCards.push(id)

            setNumCards(loadedCards)

            if (loadedCards.length === endLength) {
                setCardsLoaded(true)
                return
            }
        },
        [numCards],
    )

    const body = useMemo(
        () => (
            <>
                <View style={styles.logoContainer}>
                    <Image
                        source={{ uri: theme.image.previewImageLogo }}
                        style={styles.logo}
                    />
                </View>
                <View style={styles.headerContainer}>
                    <Text
                        style={styles.header}
                        numberOfLines={2}
                        ellipsizeMode={"tail"}
                    >
                        {title}
                    </Text>
                </View>
                <View style={styles.listContainer}>
                    {sortedList.slice(0, LAST_CARD_INDEX).map(elem => {
                        const votedOn = currentVotes.some(
                            vote => vote.optionId === elem.id,
                        )

                        return (
                            <PreviewImageCardTemplate
                                key={elem.id}
                                seeWhoVoted={seeWhoVoted}
                                option={elem}
                                totalCounter={totalCounter}
                                onLoaded={handleCardLoaded}
                                isChecked={votedOn}
                            />
                        )
                    })}
                </View>
            </>
        ),
        [currentVotes.length, handleCardLoaded, seeWhoVoted, sortedList.length],
    )

    return (
        <View style={styles.container}>
            {theme.image.previewImageBackground ? (
                <ImageBackground
                    source={theme.image.previewImageBackground}
                    resizeMode="stretch"
                    onLoad={onBackgroundCompleted}
                    style={styles.imageBackground}
                >
                    {body}
                </ImageBackground>
            ) : (
                body
            )}
        </View>
    )
}

export { PreviewImage }
